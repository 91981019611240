import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <section
      className="coming-soon flex flex-wrap flex-col content-center justify-center h-screen px-3 sm:px-56 sm:py-320"
      data-sal="zoom-in"
      data-sal-duration="1000"
      data-sal-delay="300"
    >
      <h1 className="text-5xl sm:text-8xl mt-0">Próximamente</h1>
      <p className="text-3xl sm:text-5xl mb-0">
        Estamos trabajando para tener disponible esta sección a la brevedad...
      </p>
      <Link to="/" className="text-3xl sm:text-5xl ">
        Regresar a la página principal
      </Link>
    </section>
  </Layout>
)

export default SecondPage
